export const PRIMENG_TRANSLATIONS_PT_PT = {
  startsWith: 'Começa com',
  contains: 'Contém',
  notContains: 'Não contém',
  endsWith: 'Termina com',
  equals: 'É igual a',
  notEquals: 'Não é igual a',
  noFilter: 'Sem filtro',
  lt: 'Menor que',
  lte: 'Menor ou igual a',
  gt: 'Maior que',
  gte: 'Maior ou igual a',
  is: 'É',
  isNot: 'Não é',
  before: 'Antes',
  after: 'Depois',
  dateIs: 'Data é',
  dateIsNot: 'Data não é',
  dateBefore: 'Data é anterior a',
  dateAfter: 'Data é posterior a',
  clear: 'Limpar',
  apply: 'Aplicar',
  matchAll: 'Corresponder a todos',
  matchAny: 'Corresponder a qualquer',
  addRule: 'Adicionar regra',
  removeRule: 'Remover regra',
  accept: 'Sim',
  reject: 'Não',
  choose: 'Escolher',
  upload: 'Carregar',
  cancel: 'Cancelar',
  dayNames: [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
  ],
  dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
  dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sá'],
  monthNames: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  monthNamesShort: [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ],
  dateFormat: 'dd/mm/yy',
  firstDayOfWeek: 1,
  today: 'Hoje',
  weekHeader: 'Sem',
  weak: 'Fraca',
  medium: 'Média',
  strong: 'Forte',
  passwordPrompt: 'Introduza uma palavra-passe',
  emptyFilterMessage: 'Nenhum resultado encontrado',
  emptyMessage: 'Sem opções disponíveis',
  aria: {
    trueLabel: 'Verdadeiro',
    falseLabel: 'Falso',
    nullLabel: 'Não selecionado',
    pageLabel: 'Página',
    firstPageLabel: 'Primeira página',
    lastPageLabel: 'Última página',
    nextPageLabel: 'Próxima página',
    previousPageLabel: 'Página anterior',
    selectLabel: 'Selecionar',
    unselectLabel: 'Desselecionar',
    expandLabel: 'Expandir',
    collapseLabel: 'Recolher',
    slideNumber: 'Número do slide',
  },
  // Traduções específicas para upload de ficheiros. Acho que não está a funcionar
  fileSizeTypes: ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
  maxFileSize: 'Tamanho máximo do ficheiro excedido.',
  invalidFileSizeMessageSummary: '{0}: Tamanho do ficheiro inválido, ',
  invalidFileSizeMessageDetail: 'o tamanho máximo de carregamento é {0}.',
  invalidFileTypeMessageSummary: '{0}: Tipo de ficheiro inválido, ',
  invalidFileTypeMessageDetail: 'tipos de ficheiro permitidos: {0}.',
  chooseFiles: 'Escolher ficheiros',
  chooseFile: 'Escolher ficheiro',
  dropFiles: 'Arraste os ficheiros para aqui para carregar',
  dropFile: 'Arraste o ficheiro para aqui para carregar',
  selected: '{0} ficheiros selecionados',
  uploadFailed: 'Falha no carregamento',
  uploadSuccessful: 'Carregamento bem-sucedido',
  cancelUpload: 'Cancelar carregamento',
  removeFile: 'Remover ficheiro',
  removeFileConfirmation:
    'Tem a certeza de que deseja remover o ficheiro selecionado?',
  removeFilesConfirmation:
    'Tem a certeza de que deseja remover os ficheiros selecionados?',
  totalSize: 'Tamanho total: {0}',
  bytes: 'bytes',
  showPreview: 'Mostrar pré-visualização',
  hidePreview: 'Ocultar pré-visualização',
  uploadCompleted: 'Carregamento concluído',
};
