import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import {
  ApplicationConfig,
  importProvidersFrom,
  LOCALE_ID,
} from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { routes } from './app.routes';
import { httpRequestInterceptor } from './core/helpers/interceptors/http-request.interceptor';
import { PRIMENG_TRANSLATIONS_PT_PT } from './primeng-locale.config';

registerLocaleData(localePt, 'pt-PT');

export const appConfig: ApplicationConfig = {
  providers: [
    MessageService,
    provideRouter(routes),
    provideHttpClient(withInterceptors([httpRequestInterceptor])),
    { provide: APP_BASE_HREF, useValue: '/' },
    provideAnimationsAsync(),
    importProvidersFrom(PrimeNGConfig),
    {
      provide: PrimeNGConfig,
      useFactory: () => {
        const config = new PrimeNGConfig();
        config.setTranslation(PRIMENG_TRANSLATIONS_PT_PT);
        return config;
      },
    },
    { provide: LOCALE_ID, useValue: 'pt-PT' },
  ],
};
